.container {
  height: auto;
}



body{
  margin: 0;
  cursor: none;
  overflow-x: hidden;
}

 /* Mask image for the custom cursor */
 .custom-cursor-mask {
  width: 100vh;
  height: 100vw;
  -webkit-mask-image: url('https://alishmanandhar.com.np/stars.jpg'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/stars.jpg'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background-color: none;
}

.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  background-image: url('https://alishmanandhar.com.np/circle.png'); /* Default cursor image */
  background-size: cover;
  z-index: 9999; /* Ensure the cursor appears above other content */
  pointer-events: none; /* Ensure the cursor doesn't interfere with mouse events */
}

.background{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url("https://alishmanandhar.com.np/stars.jpg");
  background-size: cover;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape1.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape1.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: bottom left;
  mask-position: bottom left;
  background-color: none;
  z-index: -1;
}

@keyframes shape4_animation {
  0%,100%{ 
    top: 0px;
  }
  50% {
    top: 20px;
  }
}

.background1{
  position: absolute;
  width: 100vw;
  height: 70vh;
  right: 200px;
  background-image: url("https://alishmanandhar.com.np/stars.jpg");
  background-size: cover;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape4.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape4.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top right;
  mask-position: top right;
  background-color: none;
  z-index: -1;
  mask-size: 400px 400px;
  animation: shape4_animation 3s linear infinite;
}
.background2{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url("https://alishmanandhar.com.np/stars.jpg");
  background-size: cover;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape2.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape2.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: bottom left;
  mask-position: bottom right;
  background-color: none;
  z-index: -1;
}
.background3{
  margin-top: -100px;
  position: absolute;
  height: 150px;
  width: 150px;
  background-image: url("https://alishmanandhar.com.np/stars.jpg");
  background-size: cover;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape3.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape3.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: none;
  z-index: -1;
  -webkit-mask-size: contain; /* Make the mask image fit contain */
  mask-size: contain;
}

.mainTitleContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: none;
}

@function makelongshadow($length,$angle) {
  $val: 0px 0px transparent;
  @for $i from 1 through $length {
    $val: #{$val}, #{$i}px #{$i*$angle}px #000000;
  }
  @return $val;
}

@keyframes title_aniamtion {
  0% {
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0);
  }
  25% {
    text-shadow: 2px 2px 20px rgba(92, 91, 91, 0.5);
  }
  40% {
    text-shadow: makelongshadow(5,-1);
  }
  70% {
    text-shadow: makelongshadow(20,2.5);
  }
  100% {
    text-shadow: makelongshadow(-5,-2);
  }
  
}

.title {
  font-size: 100px;
  font-family: "Lilita One", sans-serif;
  color: white;
  text-shadow: 12px 12px 0px rgba(0, 0, 0, 1);
  animation: title_aniamtion 3s forwards;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: linear;
  z-index: 1;
}

@keyframes saturn_animation {
  0% {
    top:40%;
    left: 20%;
    height: 70px;
    transform: rotate(0deg)
  }

  70% {
    top:20%;
    left: 60%;
    height: 170px;
    transform: rotate(-50deg)
  }
  100% {
    top:20%;
    left: 60%;
    height: 170px;
    transform: rotate(-45deg)
  }
}

.saturn {
  position:absolute;
  top:40%;
  left: 20%;
  height: 70px;
  z-index: 2;
  animation:saturn_animation 2.5s forwards;
}

.alien-box {
  position:absolute;
  top:40%;
  left: 10%;
  transform: scaleX(-1);
  z-index: 1;
}

.alien {
  height: 250px;
  z-index: 2;
  position:absolute;
}

@keyframes hand_animation {
  0%{
    transform: rotate(-25deg)
  }
  50%{
    transform: rotate(0deg);
  }
  100%{
    transform:rotate(-25deg)
  }
}

.hand {
  height: 80px;
  z-index: 1;
  position:absolute;
  left: -40px;
  top: 60px;
  transform-origin: bottom right;
  animation:hand_animation 2.5s infinite;
}

@keyframes rotateRock {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rock{
  height: 120px;
  position: absolute;
  bottom: 15%;
  left: 10%;
  animation: rotateRock 4s linear infinite;
  z-index: 1;
}

.telescope {
  height: 250px;
  position: absolute;
  bottom: 0px;
  transform: scaleX(-1);
  right: 30%;
}

@keyframes spacewalker_animation {
  from {
    opacity: 0;
    height: 500px;
    top: 30%;
  }
  to {
    opacity: 1;
    top: 40%;
  }
}

.spacewalker {
  position:absolute;
  height: 400px;
  right: 10%;
  top: 30%;
  animation: spacewalker_animation 3s forwards;
}

@keyframes ship_animation {
  0% {
    top: 27%;
    left: 25%;
  }
  50%{
    top: 25%;
    left: 23%;
  }
  100%{
    top: 27%;
    left: 25%;
  }
}

.alienship_box{
  position:absolute;
  top: 30%;
  left: 30%;
  animation: ship_animation 3s infinite;
}
@keyframes alienship_animation {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.alienship {
  height: 100px;
  transform: rotate(-30deg);
  animation: alienship_animation 3s forwards;
  z-index: 2;
}

@keyframes light_animation {
  0% {
    opacity: 0.3;
    
  }
  50% {
    opacity: 0.8;
  }
  100%{
    opacity: 0.3;
  }
}

.light {
  position: absolute;
  height: 120px;
  transform: rotate(-30deg);
  opacity: 0.8;
  left: 90px;
  top: 75%;
  animation: light_animation 3s infinite;
  z-index: 1;
}

.shape1{
  height: 420px;
  position:absolute;
  bottom: -70px;
  left: -100px;
  z-index: 0;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape1.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape1.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}

.subtitle {
  font-family: "Lilita One", sans-serif;
  font-size: 18px;
}


// nav


.logo{
  font-size: 40px;
  position: absolute;
  top: 30px;
  left: 50px;
  font-family: "Knewave", system-ui;
  font-weight: 400;
  font-style: normal;
}


.masked-cursor {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px; /* Set the width of the cursor image */
  height: 32px; /* Set the height of the cursor image */
  pointer-events: none; /* Ensure the cursor doesn't interfere with mouse events */
  z-index: 9999; /* Set a high z-index to ensure the cursor appears above other content */
}

.masked-cursor img {
  width: 100%;
  height: 100%;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape3.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape3.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}


#section{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  animation: animateBg 50s linear infinite;
  overflow: hidden;
}

@keyframes animateBg{
  0%,100%{
      transform: scale(1);
  }
  50%{
      transform: scale(0.5);
  }
}

#span{
  position: absolute;
  top:50%;
  left:50%;
  width: 4px;
  height: 4px;
  background: #0e0d0d;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(20, 19, 19, 0.1),0 0 0 8px rgba(255,255,255,0.1),0 0 20px rgba(255,255,255,0.1);
  animation: animate 3s linear infinite;
}
#span::before{
  content:'';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 1px;
  background: linear-gradient(90deg,#1b1a1a,transparent);
}
@keyframes animate
{
  0%
  {
      transform: rotate(315deg) translateX(0);
      opacity: 1;
  }
  70%
  {
      opacity: 1;
  }
  100%
  {
      transform: rotate(315deg) translateX(-1000px);
      opacity: 0;
  }
}
#span:nth-child(1){
  top: 0;
  right: 0;
  left: initial;
  animation-delay: 0s;
  animation-duration: 1s;
}
#span:nth-child(2){
  top: 0;
  right: 80px;
  left: initial;
  animation-delay: 0.2s;
  animation-duration: 3s;
}
#span:nth-child(3){
  top: 80;
  right: 0px;
  left: initial;
  animation-delay: 0.4s;
  animation-duration: 2s;
}
#span:nth-child(4){
  top: 0;
  right: 180px;
  left: initial;
  animation-delay: 0.6s;
  animation-duration: 1.5s;
}
#span:nth-child(5){
  top: 0;
  right: 400px;
  left: initial;
  animation-delay: 0.8s;
  animation-duration: 2.5s;
}
#span:nth-child(6){
  top: 0;
  right: 600px;
  left: initial;
  animation-delay: 1s;
  animation-duration: 3s;
}
#span:nth-child(7){
  top: 300px;
  right: 0px;
  left: initial;
  animation-delay: 1.2s;
  animation-duration: 1.75s;
}
#span:nth-child(8){
  top: 0px;
  right: 700px;
  left: initial;
  animation-delay: 1.4s;
  animation-duration: 1.25s;
}
#span:nth-child(9){
  top: 0px;
  right: 1000px;
  left: initial;
  animation-delay: 0.75s;
  animation-duration: 2.25s;
}
#span:nth-child(9){
  top: 0px;
  right: 450px;
  left: initial;
  animation-delay: 2.75s;
  animation-duration: 2.75s;
}

@media only screen and (max-width: 900px) {
  .mainTitleContainer {
    position:absolute;
    height: auto;
    width: auto;
    top: 30%;
    left: 18%;
  }

  .logo{

    text-shadow: 2px 2px 3px #ffffff;
  }

  .title {
    font-size: 60px;
  }
  
  .subtitle{
    z-index: 7;
    text-shadow: 2px 2px 3px #ffffff;
  }

  @keyframes saturn_animation {
    0% {
      top:20%;
      left: 10%;
      height: 40px;
      transform: rotate(0deg)
    }
  
    70% {
      top:10%;
      left: 50%;
      height: 100px;
      transform: rotate(-50deg)
    }
    100% {
      top:10%;
      left: 50%;
      height: 100px;
      transform: rotate(-45deg)
    }
  }

  .alien-box {
    position:absolute;
    top:40%;
    left: 20%;
    transform: scaleX(-1);
    z-index: 1;
  }

  .telescope {
    height: 200px;
    position: absolute;
    bottom: 0px;
    transform: scaleX(-1);
    right: 0%;
  }

  @keyframes spacewalker_animation {
    from {
      opacity: 0;
      height: 400px;
      top: 35%;
    }
    to {
      opacity: 1;
      top: 45%;
    }
  }
  
  .spacewalker {
    position:absolute;
    height: 250px;
    right: 10%;
    top: 45%;
    animation: spacewalker_animation 3s forwards;
  }

  
@keyframes ship_animation {
  0% {
    top: 27%;
    left: 25%;
  }
  50%{
    top: 25%;
    left: 23%;
  }
  100%{
    top: 27%;
    left: 25%;
  }
}

.alienship_box{
  position:absolute;
  top: 10%;
  left: 5%;
  animation: ship_animation 3s infinite;
}

@keyframes ship_animation {
  0% {
    top: 10%;
    left: 12%;
  }
  50%{
    top: 15%;
    left: 14%;
  }
  100%{
    top: 10%;
    left: 12%;
  }
}


.alienship {
  height: 70px;
  transform: rotate(-30deg);
  animation: alienship_animation 3s forwards;
  z-index: 2;
}

.light {
  position: absolute;
  height: 100px;
  transform: rotate(-30deg);
  opacity: 0.8;
  left: 60px;
  top: 70%;
  animation: light_animation 3s infinite;
  z-index: 1;
}


.background{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url("https://alishmanandhar.com.np/stars.jpg");
  background-size: cover;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape1.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape1.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: bottom left;
  mask-position: bottom left;
  background-color: none;
  z-index: -1;
}

.background1{
  position: absolute;
  width: 100vw;
  height: 70vh;
  right:-70px;
  background-image: url("https://alishmanandhar.com.np/stars.jpg");
  background-size: cover;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape4.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape4.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top right;
  mask-position: top right;
  background-color: none;
  z-index: -1;
  mask-size: 250px 250px;
  animation: none;
}
.background2{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url("https://alishmanandhar.com.np/stars.jpg");
  background-size: cover;
  -webkit-mask-image: url('https://alishmanandhar.com.np/shape2.png'); /* Mask image */
  mask-image: url('https://alishmanandhar.com.np/shape2.png'); /* Mask image */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: bottom left;
  mask-position: bottom right;
  background-color: none;
  z-index: -1;
}
.background3{
  display: none;
}

}

